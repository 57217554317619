<template>
  <div class="design flex flex-col h-full">
    <h1 class="px-4 py-2">Design</h1>
    <div class="flex justify-between px-4 py-3 shadow-sm">
      <div v-if="config && busy.load === 0" class="flex gap-4">
        <b-form-group label="Design" v-slot="{ ariaDescribedby }">
          <b-form-select
            size="sm"
            :aria-describedby="ariaDescribedby"
            v-model="design.template"
            :options="templates"
            required
            @change="onTemplate"
          ></b-form-select>
        </b-form-group>

        <b-form-group label="Logo" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            v-model="design.label_style"
            :options="labelStyles"
            :aria-describedby="ariaDescribedby"
            name="label-style"
            stacked
            @change="onLabelStyle"
          ></b-form-radio-group>
        </b-form-group>

        <b-form-group
          v-if="config.settings.frits"
          label="Frits Decor"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio-group
            v-model="design.stone_style"
            :options="stoneStyles"
            :aria-describedby="ariaDescribedby"
            name="stone-style"
            stacked
            @change="onStoneStyle"
          ></b-form-radio-group>
        </b-form-group>

        <b-form-group
          v-if="config.settings.frits"
          label="Frits Coloring"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio-group
            v-model="design.stone_coloring"
            :options="stoneColorings"
            :aria-describedby="ariaDescribedby"
            name="stone-coloring"
            stacked
            @change="onStoneColoring"
          ></b-form-radio-group>
        </b-form-group>

        <b-form-group
          v-if="config.settings.frits"
          label="Frits Palette"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio-group
            v-model="design.frits_palette"
            :options="fritsPalettes"
            :aria-describedby="ariaDescribedby"
            name="frits-palettes"
            stacked
          ></b-form-radio-group>
        </b-form-group>

        <div class="text-center">
          <div>
            <span v-if="design.wax_coloring === 'single'">Wax</span>
            <span v-else>Wax 1</span>
          </div>
          <color-button
            class="mt-2"
            :active="colorPicker === 'wax_mask1'"
            :color="activeColors.wax_mask1"
            @click="onColorPicker('wax_mask1')"
          />
        </div>

        <div v-if="design.wax_coloring.endsWith('colors')" class="text-center">
          <div>Wax 2</div>
          <color-button
            class="mt-2"
            :active="colorPicker === 'wax_mask2'"
            :color="activeColors.wax_mask2"
            @click="onColorPicker('wax_mask2')"
          />
        </div>

        <div v-if="design.wax_coloring === 'three-colors'" class="text-center">
          <div>Wax 3</div>
          <color-button
            class="mt-2"
            :active="colorPicker === 'wax_mask3'"
            :color="activeColors.wax_mask3"
            @click="onColorPicker('wax_mask3')"
          />
        </div>

        <div
          v-if="config.settings.frits"
          class="text-center"
        >
          <div>
            <span v-if="design.stone_coloring === 'single'">Frits</span>
            <span v-else>Frits 1</span>
          </div>
          <color-button
            class="mt-2"
            :active="colorPicker === 'stone_mask1'"
            :color="activeColors.stone_mask1"
            @click="onColorPicker('stone_mask1')"
          />
        </div>

        <div
          v-if="config.settings.frits && design.stone_coloring === 'multi'"
          class="text-center"
        >
          <div>Frits 2</div>
          <color-button
            class="mt-2"
            :active="colorPicker === 'stone_mask2'"
            :color="activeColors.stone_mask2"
            @click="onColorPicker('stone_mask2')"
          />
        </div>

        <div class="flex flex-col gap-2">
          <div>Settings</div>

          <b-form-checkbox
            v-model="design.show_dimensions"
            name="checkbox-dimensions"
            :value="1"
            :unchecked-value="0"
            @change="onShowDimensions"
          >
            Show dimensions
          </b-form-checkbox>

          <b-form-checkbox
            v-model="design.show_lines"
            name="checkbox-lines"
            :value="1"
            :unchecked-value="0"
            @change="onShowLines"
          >
            Show lines
          </b-form-checkbox>

          <b-form-checkbox
            v-model="design.show_docs"
            name="checkbox-docs"
            :value="1"
            :unchecked-value="0"
          >
            Show docs
          </b-form-checkbox>
        </div>
      </div>
      <div v-else class="flex items-center">
        <h1 class="font-weight-bold">Loading..</h1>
      </div>

      <div class="flex flex-col items-end justify-between">
        <div class="mt-2">
          <div class="flex flex-row items-end gap-2">
            <b-button
              variant="primary"
              size="sm"
              @click="saveDesign"
              :disabled="busy.save > 0"
            >
              <div v-if="design.id">Update</div>
              <div v-else>Save</div>
            </b-button>

            <b-button
              variant="danger"
              size="sm"
              :disabled="design.id === null || busy.save > 0"
              @click="deleteDesign"
            >
              Delete
            </b-button>

            <b-button
              variant="white"
              size="sm"
              :disabled="busy.export > 0"
              @click="exportDesign"
            >
              <span v-if="busy.export > 0">Exporting..</span>
              <span v-else>Export</span>
            </b-button>
          </div>

          <div class="flex flex-row items-end justify-content-end gap-2 mt-2">
            <b-button
              variant="white"
              size="sm"
              :disabled="busy.save > 0"
              @click="createDoc"
            >
              <span>Create doc</span>
            </b-button>
          </div>
        </div>
        <h1 class="text-xl">
          <div v-if="design.id">
            {{ design.design_name }}
            - {{ design.update_user }}
            {{ design.update_ts | shortdate }}
            {{ design.update_ts | time }}
          </div>
        </h1>
      </div>
    </div>

    <div class="layout flex">
      <color-picker
        v-if="colorPicker"
        :colors="colors"
        :activeColor="activeColors[colorPicker]"
        @input="onColorPicked"
        :type="colorPicker.startsWith('wax_mask') ? 'wax' : 'frits'"
        :palette="colorPicker.startsWith('wax_mask') ? 'opaque' : design.frits_palette"
      />

      <div v-if="busy.load > 0" class="flex items-center justify-content-center w-full">
        <b-spinner small type="grow" label="Spinning"></b-spinner>
      </div>
      <div v-else class="layers relative h-full flex-grow-1 transform-gpu">
        <layer-display
          v-for="layer in layers"
          :key="layer.name"
          :layer="layer"
          :ts="ts"
        ></layer-display>

        <template v-if="busy.load === 0 && this.design.show_docs">
          <doc-point-display
            v-for="doc in docs"
            :key="`doc_point_${doc.id}`"
            :width="width"
            :height="height"
            :doc="doc"
            @open="onDocOpen"
          />
        </template>
      </div>
    </div>

    <doc-modal
      v-if="docModal.showModal"
      :data="docModal"
      @deleted="onDocDeleted"
    />
  </div>
</template>

<script>
import { isColorDark, downloadFile } from '@/helpers';

const LayerDisplay = () => import('@/components/design/LayerDisplay.vue');
const DocPointDisplay = () => import('@/components/design/DocPointDisplay.vue');
const DocModal = () => import('@/components/design/DocModal.vue');
const ColorButton = () => import('@/components/design/ColorButton.vue');
const ColorPicker = () => import('@/components/design/ColorPicker.vue');

export default {
  name: 'Design',
  components: {
    LayerDisplay,
    DocPointDisplay,
    DocModal,
    ColorButton,
    ColorPicker,
  },
  computed: {
    activeColors() {
      return {
        wax_mask1: this.findColor(this.design.wax_mask1),
        wax_mask2: this.findColor(this.design.wax_mask2),
        wax_mask3: this.findColor(this.design.wax_mask3),
        stone_mask1: this.findColor(this.design.stone_mask1),
        stone_mask2: this.findColor(this.design.stone_mask2),
      };
    },
  },
  data() {
    return {
      busy: {
        load: 0,
        save: 0,
        export: 0,
      },
      ts: parseInt(new Date().getTime() / 1000, 10),
      colorPicker: null,
      layers: null,
      defaultDesign: {
        id: null,
        design_name: 'New Design',
        create_user: '',
        update_user: '',
        create_ts: null,
        update_ts: null,
        template: 'coppola',
        wax_mask1: '00000000',
        wax_mask2: '00000000',
        wax_mask3: '00000000',
        wax_coloring: 'single',
        stone_mask1: '00000000',
        stone_mask2: '00000000',
        stone_coloring: 'multi',
        stone_style: 'half',
        label_style: 'black',
        show_dimensions: 1,
        show_lines: 1,
        show_docs: 1,
      },
      design: {
        id: null,
        design_name: 'New Design',
        create_user: '',
        update_user: '',
        create_ts: null,
        update_ts: null,
        template: 'coppola',
        wax_mask1: '00000000',
        wax_mask2: '00000000',
        wax_mask3: '00000000',
        wax_coloring: 'single',
        stone_mask1: '00000000',
        stone_mask2: '00000000',
        stone_coloring: 'multi',
        stone_style: 'half',
        frits_palette: 'opaque',
        label_style: 'black',
        show_dimensions: 1,
        show_lines: 1,
        show_docs: 1,
      },
      templates: [],
      docs: [],
      config: null,
      waxColorings: [
        { text: 'Single', value: 'single' },
        { text: 'Two-colors', value: 'two-colors' },
        { text: 'Three-colors', value: 'three-colors' },
      ],
      stoneStyles: [
        { text: 'Full', value: 'full' },
        { text: 'Half', value: 'half' },
        { text: 'None', value: 'none' },
      ],
      stoneColorings: [
        { text: 'Single', value: 'single' },
        { text: 'Multi', value: 'multi' },
      ],
      labelStyles: [
        { text: 'White', value: 'white' },
        { text: 'Black', value: 'black' },
      ],
      fritsPalettes: [
        { text: 'Opaque', value: 'opaque' },
        { text: 'Transparent', value: 'transparent' },
        { text: 'Iris', value: 'iris' },
      ],
      unknownColor: {
        name: 'Choose a color',
        description: '',
        hex: '000000',
      },
      colors: [],
      width: 0,
      height: 0,
      docModal: {
        showModal: false,
        doc: null,
      },
    };
  },
  methods: {
    findColor(hex) {
      const foundColor = this.colors.find((color) => color.hex === hex) ?? this.unknownColor;
      return foundColor;
    },
    renderWaxColor() {
      const waxLayers = ['wax_mask1', 'wax_mask2', 'wax_mask3'];
      waxLayers.forEach((waxLayer) => {
        this.layers
          .filter((layer) => layer.name.includes(waxLayer))
          .forEach((layer) => {
            layer.hex = this.design[waxLayer];
          });
      });
    },
    renderStoneSize() {
      this.layers
        .filter((layer) => layer.name.includes('_stone_'))
        .forEach((layer) => {
          layer.render = false;
        });
      this.layers
        .filter((layer) => layer.name.includes(`${this.design.stone_style}_stone`))
        .forEach((layer) => {
          layer.render = true;
        });
    },
    renderStoneColor() {
      const stoneMasks = Object.keys(this.design).filter((key) => key.includes('stone_mask'));
      stoneMasks.forEach((stoneMask) => {
        this.layers
          .filter((layer) => layer.name.includes(stoneMask))
          .forEach((layer) => {
            layer.hex = (
              this.design.stone_coloring === 'single'
                ? this.design.stone_mask1
                : this.design[stoneMask]
            );
          });
      });
    },
    renderLabelStyle() {
      this.layers
        .filter((layer) => layer.name.includes('_label_'))
        .forEach((layer) => {
          layer.render = false;
        });
      this.layers
        .filter((layer) => layer.name.includes(`label_${this.design.label_style}`))
        .forEach((layer) => {
          layer.render = true;
        });
    },
    renderDimensions() {
      this.layers
        .filter((layer) => layer.name.includes('_dimensions'))
        .forEach((layer) => {
          layer.render = this.design.show_dimensions === 1;
        });
    },
    renderLines() {
      this.layers
        .filter((layer) => layer.name.includes('_lines'))
        .forEach((layer) => {
          layer.render = this.design.show_lines === 1;
        });
    },
    renderDesign() {
      this.renderWaxColor();
      this.renderStoneSize();
      this.renderStoneColor();
      this.renderLabelStyle();
      this.renderDimensions();
      this.renderLines();
    },
    fetchLayers() {
      this.busy.load++;
      this.$http
        .get('/layer')
        .query({ template: this.design.template })
        .then((res) => {
          this.layers = res.body.layers.map((layer) => ({
            name: layer,
            ts: res.body.layers_timestamp[layer],
            hex: null,
            render: true,
          }));
          this.config = res.body.config;
          this.design.wax_coloring = res.body.config.settings.wax_coloring;
          this.renderDesign();
          this.onResize();
        })
        .catch((err) => {
          this.$toast.error(`Failed to retrieve layers: ${err.response.text}`);
        })
        .finally(() => {
          this.busy.load--;
        });
    },
    fetchDocs() {
      this.busy.load++;
      this.docs = [];
      this.$http
        .get('/template_doc')
        .query({ template: this.design.template })
        .then((res) => {
          this.docs = res.body.docs;
        })
        .catch((err) => {
          this.$toast.error(`Failed to retrieve docs: ${err.response.text}`);
        })
        .finally(() => {
          this.busy.load--;
        });
    },
    createDoc() {
      this.busy.save++;
      this.$http
        .post('/template_doc')
        .send({ template: this.design.template })
        .then((res) => {
          this.docs.push(res.body.doc);
        })
        .catch((err) => {
          this.$toast.error(`Failed to create doc: ${err.response.text}`);
        })
        .finally(() => {
          this.busy.save--;
        });
    },
    fetchColors() {
      this.busy.load++;
      this.$http
        .get('/color')
        .then((res) => {
          this.colors = res.body.colors;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch colors: ${err.response.text}`);
        })
        .finally(() => {
          this.busy.load--;
        });
    },
    fetchTemplates() {
      this.busy.load++;
      this.$http
        .get('/template')
        .then((res) => {
          this.templates = res.body.templates;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch templates: ${err.response.text}`);
        })
        .finally(() => {
          this.busy.load--;
        });
    },
    fetchDesign(id) {
      this.busy.load++;
      this.$http
        .get(`/design/${id}`)
        .then((res) => {
          if (res.body.design === null) {
            this.$toast.error(`Design #${id} not found`);
            return;
          }
          Object.keys(res.body.design).forEach((key) => {
            this.design[key] = res.body.design[key];
          });
          this.fetchLayers();
          this.fetchDocs();
        })
        .catch((err) => {
          this.$toast.error(`Failed to retrieve design: ${err.response.text}`);
        })
        .finally(() => {
          this.busy.load--;
        });
    },
    saveDesign() {
      const promptMessage = this.design.id ? 'Update design' : 'Save design';
      const designName = prompt(promptMessage, this.design.design_name);
      if (designName) {
        this.busy.save++;
        this.design.design_name = designName;
        this.$http
          .post('/design')
          .send({ design: this.design })
          .then((res) => {
            this.design = {
              ...this.design,
              ...res.body.design,
            };
            this.saveQuery();
          })
          .catch((err) => {
            this.$toast.error(`Failed to save design: ${err.response.text}`);
          })
          .finally(() => {
            this.busy.save--;
          });
      }
    },
    deleteDesign() {
      const shouldDelete = confirm(`Do you really wish to delete '${this.design.design_name}' design?`);
      if (shouldDelete) {
        this.busy.save++;
        this.$http
          .delete(`/design/${this.design.id}`)
          .then(() => {
            this.design.id = null;
            this.saveQuery();
          })
          .catch((err) => {
            this.$toast.error(`Failed to delete design: ${err.response.text}`);
          })
          .finally(() => {
            this.busy.save--;
          });
      }
    },
    exportDesign() {
      this.busy.export++;
      this.$http
        .post('/design_export')
        .send({ design: this.design })
        .then((res) => {
          downloadFile(res.body.link);
        })
        .catch((err) => {
          this.$toast.error(`Failed to export design: ${err.response.text}`);
        })
        .finally(() => {
          this.busy.export--;
        });
    },
    resetDesign() {
      const shouldReset = confirm('Do you really wish to start over? Unsaved changes will be lost.');
      if (shouldReset) {
        Object.keys(this.defaultDesign).forEach((key) => {
          this.design[key] = this.defaultDesign[key];
        });
        this.colorPicker = null;
        this.renderDesign();
        this.saveQuery();
      }
    },
    saveQuery() {
      this.$router.replace(
        { path: this.$route.path, query: { id: this.design.id } },
        () => {},
        () => {},
      );
    },
    loadQuery() {
      const { id } = this.$route.query;
      if (id) {
        this.fetchDesign(id);
      }
    },
    onResize(delayed) {
      const delay = delayed === undefined ? 1000 : 0;
      setTimeout(() => {
        const layout = document.querySelector('.layout');
        this.width = document.documentElement.clientWidth - layout.offsetLeft;
        this.height = document.documentElement.clientHeight - layout.offsetTop;
        layout.style.height = `${this.height}px`;
      }, delay);
    },
    onTemplate() {
      this.fetchLayers();
      this.fetchDocs();
    },
    onStoneStyle() {
      setTimeout(() => {
        this.renderStoneSize();
      }, 100);
    },
    onStoneColoring() {
      this.colorPicker = null;
      setTimeout(() => {
        this.renderStoneColor();
      }, 100);
    },
    onWaxColoring() {
      this.colorPicker = null;
      setTimeout(() => {
        this.renderWaxColor();
      }, 100);
    },
    onLabelStyle() {
      setTimeout(() => {
        this.renderLabelStyle();
      }, 100);
    },
    onColorPicker(colorPicker) {
      this.colorPicker = this.colorPicker === colorPicker ? null : colorPicker;
    },
    onColorPicked(color) {
      this.design[this.colorPicker] = color.hex;

      if (this.colorPicker === 'stone_mask1') {
        const multiColors = this.colors.filter((c) => c.id !== color.id && c.name === color.name && c.type === 'frits');
        if (multiColors.length > 0) {
          this.design.stone_mask2 = multiColors[0].hex;
        }
      }

      if (this.colorPicker.startsWith('wax_mask')) {
        this.renderWaxColor();
        const labelHex = this.design.wax_coloring === 'single' ? this.design.wax_mask1 : this.design.wax_mask2;
        this.design.label_style = isColorDark(labelHex) ? 'white' : 'black';
        this.onLabelStyle();
      } else {
        this.renderStoneColor();
      }
    },
    onShowDimensions() {
      setTimeout(() => {
        this.renderDimensions();
      }, 100);
    },
    onShowLines() {
      setTimeout(() => {
        this.renderLines();
      }, 100);
    },
    onDocOpen(doc) {
      this.docModal.doc = doc;
      this.docModal.showModal = true;
    },
    onDocDeleted(documentId) {
      this.docs = this.docs.filter((doc) => doc.id !== documentId);
    },
  },
  created() {
    this.fetchTemplates();
    this.fetchLayers();
    this.fetchDocs();
    this.fetchColors();
    this.loadQuery();
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
    setTimeout(() => {
      this.onResize();
    }, 2000);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  beforeRouteUpdate(to, from, next) {
    if (!to.query.id && to.name === 'Design' && from.name === 'Design') {
      this.$router.push({ name: 'Home' });
    }
    next();
  },
  watch: {
    busy: {
      deep: true,
      handler() {
        this.onResize(true);
      },
    },
  },
};
</script>

<style lang="scss">
</style>
